import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import WizardInput from 'components/wizard/WizardInput';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import { GoCheckCircle } from 'react-icons/go';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { useForm } from 'react-hook-form';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import SegmentForm from 'components/customer-segments/segment';
import Divider from 'components/common/Divider';
import dayjs from 'dayjs';

const UpdateCustomerSegmentModal = ({
  fields,
  showUpdateCustomerSegmentModal,
  setShowUpdateCustomerSegmentModal,
  fetchCustomerSegments,
  updatingCustomerSegment,
  editable = true
}) => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [loading, setLoading] = useState(false);

  const [customerSegment, setCustomerSegment] = useState(
    updatingCustomerSegment
  );
  const [criteriaError, setCriteriaError] = useState('');
  const [contacts, setContacts] = useState([]);

  const fetchContacts = async () => {
    setLoading(true);

    try {
      const response = await axiosClient.get(
        `/contacts?criteria=${JSON.stringify(customerSegment.criteria)}`
      );
      setLoading(false);

      setContacts(response.data.contacts);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Error to get contacts');
      }
      setLoading(false);
    }
  };

  const handleUpdateCustomerSegment = async () => {
    if (!editable) return;
    setLoading(true);

    try {
      await axiosClient.put(
        `/customer-segments/${customerSegment.id}`,
        customerSegment
      );
      fetchCustomerSegments();
      toast.success('Customer segment updated successfully');
      setLoading(false);
      setShowUpdateCustomerSegmentModal(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const getContactsByCriteria = () => {
    handleSubmit(async () => {
      if (customerSegment.criteria && customerSegment.criteria.length === 0) {
        setCriteriaError('Please add at least one criteria');
        return;
      }
      fetchContacts();
    })();
  };

  useEffect(() => {
    reset();
    setCustomerSegment(updatingCustomerSegment);
  }, [updatingCustomerSegment]);

  useEffect(() => {
    fetchContacts();
  }, [updatingCustomerSegment, customerSegment.criteria]);

  return (
    <>
      <Modal
        show={showUpdateCustomerSegmentModal}
        onHide={() => {
          setShowUpdateCustomerSegmentModal(false);
          reset();
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Customer Segment
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ maxHeight: '90vh', overflowY: 'auto' }}>
          <Form onSubmit={handleUpdateCustomerSegment}>
            <WizardInput
              label="Segment Name"
              name="segmentName"
              errors={errors}
              formGroupProps={{ className: 'mb-3' }}
              formControlProps={{
                ...register('segmentName', {
                  required: 'Segment name is required'
                }),
                value: customerSegment.segmentName,
                onChange: e => {
                  setCustomerSegment({
                    ...customerSegment,
                    segmentName: e.target.value
                  });
                },
                disabled: !editable
              }}
            />

            <SegmentForm
              fields={fields}
              criteria={customerSegment.criteria}
              onCriteriaChange={data =>
                setCustomerSegment({
                  ...customerSegment,
                  criteria: data
                })
              }
              loading={loading}
              criteriaError={criteriaError}
              getContactsByCriteria={getContactsByCriteria}
              editable={editable}
            />
          </Form>
          <Divider className="pt-2 pb-3" />
          <h4>Contacts meet the criteria</h4>
          <div>
            {loading ? (
              <Row className="justify-content-center mb-3 g-3">
                <Col lg={4} className="d-flex justify-content-center">
                  <Lottie
                    animationData={infiniteLoop}
                    loop={true}
                    style={{ height: '120px', width: '120px' }}
                  />
                </Col>
                <h3 className="text-center">Loading...</h3>
              </Row>
            ) : (
              <>
                {contacts.length > 0 && (
                  <p>
                    {contacts.length} contact{contacts.length > 1 ? 's' : ''}{' '}
                    found{' '}
                  </p>
                )}
                <div className="segment-table" style={{ overflowX: 'auto' }}>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th scope="col">First name</th>
                        <th scope="col">Last name</th>
                        <th scope="col" className="email">
                          Email
                        </th>
                        <th scope="col">Phone number</th>
                        <th scope="col">Source</th>
                        <th scope="col" className="text-center">
                          Text Opt-in
                        </th>
                        <th scope="col" className="text-center">
                          Email Opt-in
                        </th>
                        <th scope="col">Last contacted date</th>
                        <th scope="col">Contact lost date</th>
                        <th scope="col">Custom fields</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contacts.length > 0 ? (
                        contacts.map((contact, index) => (
                          <tr key={`contact-${index}`}>
                            <td>{contact.firstName}</td>
                            <td>{contact.lastName}</td>
                            <td>{contact.email}</td>
                            <td>{contact.phoneNumber}</td>
                            <td>{contact.source}</td>
                            <td className="text-center">
                              {contact.textOptIn ? (
                                <GoCheckCircle className="text-success fs-9" />
                              ) : (
                                <IoIosCloseCircleOutline className="text-danger fs-8" />
                              )}
                            </td>
                            <td className="text-center">
                              {contact.emailOptIn ? (
                                <GoCheckCircle className="text-success fs-9 " />
                              ) : (
                                <IoIosCloseCircleOutline className="text-danger fs-8" />
                              )}
                            </td>
                            <td width="80">
                              {contact.lastContactedDate &&
                                dayjs(contact.lastContactedDate).format(
                                  'MM/DD/YYYY HH:mm'
                                )}
                            </td>
                            <td width="80">
                              {contact.contactLostDate &&
                                dayjs(contact.contactLostDate).format(
                                  'MM/DD/YYYY HH:mm'
                                )}
                            </td>
                            <td className="text-capitalize">
                              {contact.customFields &&
                                Object.keys(contact.customFields).map(
                                  (field, index) => (
                                    <div key={index} className="d-flex gap-2">
                                      <span className="text-capitalize">
                                        {field.replaceAll('_', ' ')}:
                                      </span>

                                      <i>
                                        {contact.customFields[field] &&
                                          contact.customFields[
                                            field
                                          ].toString()}
                                      </i>
                                    </div>
                                  )
                                )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="12" className="text-center">
                            No contacts found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </>
            )}

            <div className="col-12 d-flex gap-3 justify-content-center px-4 mt-3 mb-4">
              {editable ? (
                <Button
                  disabled={loading}
                  onClick={() => handleUpdateCustomerSegment(false)}
                >
                  Save
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowUpdateCustomerSegmentModal(false);
                  }}
                >
                  Close
                </Button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

UpdateCustomerSegmentModal.propTypes = {
  fields: PropTypes.array,
  showUpdateCustomerSegmentModal: PropTypes.bool,
  setShowUpdateCustomerSegmentModal: PropTypes.func,
  fetchCustomerSegments: PropTypes.func,
  updatingCustomerSegment: PropTypes.object,
  editable: PropTypes.bool
};

export default UpdateCustomerSegmentModal;
